<template>
  <div>
    <teleport to=".modal">
      <authorize-modal
        title="Tax Payer Information"
        v-show="showPaymentDetailsModal"
        @hideModal="showPaymentDetailsModal = false"
      >
        <div class="text-left border-t border-gray-100">
          <dl>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Name</dt>
              <dd
                class="mt-1 text-md text-gray-700 sm:mt-0 sm:col-span-2 font-semibold"
              >
                {{ payerDetails.customer_name + "" }}
              </dd>
            </div>
            <div
              class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Amount</dt>
              <dd
                class="mt-1 text-lg text-gray-700 sm:mt-0 sm:col-span-2 font-semibold"
              >
                ₦ {{ formatAmount(payerDetails.Amount) }}
              </dd>
            </div>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">
                Payment Date and Time
              </dt>
              <dd
                class="mt-1 text-lg text-gray-700 sm:mt-0 sm:col-span-2 font-semibold"
              >
                {{ payerDetails.payment_date_time }}
              </dd>
            </div>
            <div
              class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Bank</dt>
              <dd
                class="mt-1 text-lg text-gray-700 sm:mt-0 sm:col-span-2 font-semibold"
              >
                {{ payerDetails.bank }}
              </dd>
            </div>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Revenue Item</dt>
              <dd
                class="mt-1 text-md text-gray-700 sm:mt-0 sm:col-span-2 font-semibold"
              >
                {{ payerDetails.revenue_item }}
              </dd>
            </div>
            <div
              class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Bank branch</dt>
              <dd
                class="mt-1 text-lg text-gray-700 sm:mt-0 sm:col-span-2 font-semibold"
              >
                {{ payerDetails.bank_branch }}
              </dd>
            </div>
            <!-- <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Revenue Item</dt>
              <dd
                class="mt-1 text-md text-gray-700 sm:mt-0 sm:col-span-2 font-semibold"
              >
                {{ payerDetails.revenue_item }}
              </dd>
            </div> -->
          </dl>
        </div>
      </authorize-modal>
    </teleport>

    <div
      v-if="loadingPayments"
      class="w-full h-full flex items-center text-center"
    >
      <i
        :class="`lg:focus:shadow-sm w-12 mx-auto text-center fas fa-circle-notch mt-4 text-5xl text-blue-primary animate-spin`"
      ></i>
    </div>
    <div
      v-else-if="!loadingPayments && noData"
      class="w-full h-full flex items-center text-center"
    >
      <i
        :class="`lg:focus:shadow-sm w-12 mx-auto text-center fas fa-circle-notch mt-4 text-5xl text-blue-primary animate-spin`"
      ></i>
    </div>
    <div v-else>
      <div class="flex flex-col">
        <div
          class="flex flex-col space-y-4 sm:space-y-0 md:flex-row md:space-x-10 md:space-x-6 mb-6 md:mb-0 border-b border-gray-100 pb-8"
        >
          <div
            class="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-10 md:space-x-6 mb-6 md:mb-0 w-full lg:w-auto"
          >
            <date-picker
              :classList="['text-gray-700']"
              :newDate="selectedDates.startDate"
              :title="'Start'"
              @updateDate="updateStartDate"
            ></date-picker>
            <date-picker
              :classList="['text-gray-700']"
              :newDate="selectedDates.endDate"
              :title="'End'"
              @updateDate="updateEndDate"
            ></date-picker>
          </div>
          <button
            id="btnSearch"
            type="button"
            @click="fetchData"
            :disabled="loadingData"
            class="w-full md:w-80 px-8 py-2 font-semibold text-white text-lg transition duration-500 ease-in-out transform rounded-lg shadow-xl bg-gradient-to-r from-green-700 hover:from-green-600 to-green-600 hover:to-green-700 focus:ring focus:ring-green-700 focus:outline-none"
          >
            <div v-if="!loadingData">Search</div>
            <div v-else class="flex items-center space-x-4 justify-center">
              <i class="fas fa-circle-notch animate-spin"></i>
              <span>Please wait...</span>
            </div>
          </button>
        </div>
        <div class="flex flex-col-reverse sm:flex-row pt-2">
          <p class="leading-relaxed text-lg font-bold text-gray-700 w-full">
            {{ displayedDate }}
          </p>
        </div>
        <div
          class="grid grid-cols-1 gap-5 mt-6 sm:grid-cols-2 lg:grid-cols-4 border-b border-gray-100 pb-8"
        >
          <div v-for="payment in payments" :key="payment.channel">
            <div
              class="border border-gray-200 bg-blue-primary opacity-95 p-6 sm:p-4 rounded-lg shadow-md sm:w-full text-gray-100"
            >
              <p class="leading-relaxed text-md font-medium">
                {{
                  payment.channel === "All"
                    ? "All CHANNELS"
                    : payment.channel.toUpperCase()
                }}
              </p>

              <!-- <p class="leading-relaxed font-semibold text-lg">{{ channel }} -</p> -->
              <h2 class="font-bold text-lg text-left">
                ₦ {{ formatAmount(payment.data.currYear.Amount || 0.0) }}
              </h2>
              <p>
                <span class="text-sm text-gray-300"
                  >({{ formatNumber(payment.data.currYear.Txn) }}

                  {{
                    payment.data.currYear.Txn == 1
                      ? "transaction"
                      : "transactions"
                  }})</span
                >
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- <h2
          class="text-lg font-bold text-gray-700 title-font mb-2 mt-8 uppercase"
        >
          Top 10 payers {{ paymentInterval != null ? "today" : "" }}
        </h2> -->

      <div class="flex flex-row -space-x-px mb-6 mt-8">
        <button
          @click="selectTab('topPayers')"
          class="w-full sm:w-auto transition duration-300 shadow-sm rounded-sm px-4 opacity-90 text-xs sm:text-sm py-2 sm:py-1 uppercase font-semibold sm:font-medium"
          :class="`${
            tab == 'topPayers'
              ? 'bg-primary text-white'
              : 'text-primary border border-dashed border-blue-primary-dark'
          }`"
        >
          Top 10 payers {{ paymentInterval != null ? "today" : "" }}
        </button>
        <button
          @click="selectTab('allPayers')"
          class="w-full sm:w-auto transition duration-300 shadow-sm rounded-sm px-4 opacity-90 text-xs sm:text-sm py-2 sm:py-1 uppercase font-semibold sm:font-medium"
          :class="`${
            tab == 'topPayers'
              ? 'text-primary border border-dashed border-blue-primary-dark'
              : 'bg-primary text-white'
          }`"
        >
          All payers
        </button>
      </div>

      <div v-if="tab === 'topPayers'">
        <div
          v-if="loadingData"
          class="w-full h-full flex items-center text-center"
        >
          <i
            :class="`lg:focus:shadow-sm w-12 mx-auto text-center fas fa-circle-notch mt-4 text-5xl text-blue-primary animate-spin`"
          ></i>
        </div>

        <top-payers
          v-else
          :topPayers="getTopPayers"
          @viewDetails="viewPaymentDetails"
        ></top-payers>
      </div>
      <div v-else>
        <div
          v-if="loadingData"
          class="w-full h-full flex items-center text-center"
        >
          <i
            :class="`lg:focus:shadow-sm w-12 mx-auto text-center fas fa-circle-notch mt-4 text-5xl text-blue-primary animate-spin`"
          ></i>
        </div>
        <all-payers
          v-else
          :selectedDates="selectedDates"
          :allPayers="getAllPayments"
          @nextRecords="gotoNextRecords"
          @prevRecords="gotoPrevRecords"
        ></all-payers>
      </div>
    </div>
  </div>
</template>

<script>
// import { mixin as clickaway } from "vue-clickaway";
import DatePicker from "@/components/DatePicker2.vue";
import moment from "moment";
import numeral from "numeral";
import { mapActions, mapGetters } from "vuex";
import AuthorizeModal from "@/components/AuthorizeModal.vue";
import TopPayers from "@/components/payments/TopPayers.vue";
import AllPayers from "@/components/payments/AllPayers.vue";
export default {
  //  mixins: [clickaway],
  components: {
    DatePicker,
    AuthorizeModal,
    TopPayers,
    AllPayers,
  },
  data: () => ({
    tab: "topPayers",
    canViewPayments: false,
    channel: "Bank",
    showPaymentDetailsModal: false,
    paymentInterval: null,
    payerDetails: {},
    selectedDates: {
      startDate: new Date(),
      endDate: new Date(),
    },
    showChart: false,
    limit: 20,
    page: 1,
    displayedDate: "",
    loadingPayments: true,
    noData: false,
    filter: false,
    filters: ["All", "Bank", "POS", "Internet", "IFIS"],
    loadingData: false,
  }),
  watch: {
    tab(val) {
      if (val !== "topPayers") {
        this.limit = 20;
        this.fetchAllPayments();
      } else {
        this.limit = 10;
        this.fetchTopPayers();
      }
    },
  },
  computed: {
    ...mapGetters(["getPaymentTotal", "getTopPayers", "getAllPayments"]),
    payments() {
      return this.$store.getters["report/summaryData"];
    },
  },
  methods: {
    ...mapActions([
      "getPaymentTotalByFilter",
      "getTopPayersByFilter",
      "getPayments",
    ]),
    checkIfisAndInternet(payment) {
      if (
        payment.channel.toLowerCase() === "ifis" &&
        payment.data.currYear.Amount == 0
      )
        return false;
      if (
        payment.channel.toLowerCase() === "internet" &&
        payment.data.currYear.Amount == 0
      )
        return false;

      return true;
    },
    gotoNextRecords() {
      this.loadingData = true;
      this.fetchAllPayments(this.getAllPayments?.next);
    },
    gotoPrevRecords() {
      this.loadingData = true;
      this.fetchAllPayments(this.getAllPayments?.previous);
    },
    selectTab(tab) {
      this.tab = tab;
    },
    filterSelected(channel) {
      this.channel = channel;
      this.filter = false;
    },
    formatNumber(num) {
      return numeral(num).format("0,0");
    },
    formatAmount(amt) {
      return numeral(amt).format("0,0.00");
    },
    prepareDates() {
      let dt = this.selectedDates.startDate;
      let dt2 = this.selectedDates.endDate;
      let date = {
        channel: this.channel,
        startDate: `${dt.getDate()}/${dt.getMonth() + 1}/${dt.getFullYear()}`,
        endDate: `${dt2.getDate()}/${dt2.getMonth() + 1}/${dt2.getFullYear()}`,
      };
      return date;
    },
    prepareDisplayDates(d1, d2) {
      return `${moment(d1).format("Do MMM, YYYY")} to ${moment(d2).format(
        "Do MMM, YYYY"
      )}`;
    },

    away(e) {
      //    console.log(e.target.className, this.$refs.filter_item.className);

      if (this.$refs.filter_item) {
        if (
          e.srcElement != this.$refs.drpChannel &&
          e.srcElement.className != this.$refs.filter_item.className
        )
          this.filter = false;
      }
    },
    fetchData() {
      this.loadingData = true;
      clearInterval(this.paymentInterval);
      this.paymentInterval = null;
      this.displayedDate = this.prepareDisplayDates(
        this.selectedDates.startDate,
        this.selectedDates.endDate
      );

      this.$store
        .dispatch(
          "report/getSummaryData",
          this.formatDates(
            this.selectedDates.startDate,
            this.selectedDates.endDate
          )
        )
        .then(() => {
          this.fetchTopPayers();
          this.fetchAllPayments();
        })
        .catch((err) => {
          console.log(err);
          this.loadingData = false;
        });
    },
    fetchTopPayers(nav = null) {
      let nxt =
        nav == null
          ? {
              limit: this.tab === "topPayers" ? 10 : this.limit,
              page: this.page,
            }
          : nav;

      let filterValues = {
        channel: "All",
        ...this.formatDates(
          this.selectedDates.startDate,
          this.selectedDates.endDate
        ),
        ...nxt,
      };
      this.getTopPayersByFilter(filterValues)
        .then(() => {
          this.loadingData = false;
          this.loadingPayments = false;
        })
        .catch((err) => {
          console.log(err);

          this.loadingData = false;
          this.loadingPayments = false;
        });
    },
    fetchAllPayments(nav = null) {
      let nxt =
        nav == null
          ? {
              limit: this.tab === "topPayers" ? 10 : this.limit,
              page: this.page,
            }
          : nav;

      let filterValues = {
        channel: "All",
        ...this.formatDates(
          this.selectedDates.startDate,
          this.selectedDates.endDate
        ),
        ...nxt,
      };
      this.getPayments(filterValues)
        .then(() => {
          this.loadingData = false;
          this.loadingPayments = false;
        })
        .catch((err) => {
          console.log(err);

          this.loadingData = false;
          this.loadingPayments = false;
        });
    },
    viewPaymentDetails(details) {
      //  console.log(details);
      this.payerDetails = details;
      this.showPaymentDetailsModal = true;
    },
    updateStartDate(newDate) {
      this.selectedDates.startDate = newDate;
      clearInterval(this.paymentInterval);
    },
    updateEndDate(newDate) {
      this.selectedDates.endDate = newDate;
      clearInterval(this.paymentInterval);
    },
    formatDates(dt1, dt2) {
      return {
        startDate: moment(dt1).format("YYYY-MM-DD"),
        endDate: moment(dt2).format("YYYY-MM-DD"),
      };
    },

    initPayments() {
      // let dt = this.selectedDates.startDate;
      // let dt2 = this.selectedDates.endDate;
      // let date = {
      //   // channel: this.channel,
      //   startDate: `${dt.getFullYear()}-${dt.getMonth() + 1}-1`,
      //   endDate: `${dt2.getFullYear()}-${dt2.getMonth() + 1}-${dt2.getDate()}`,
      // };
      this.displayedDate = `1st ${moment().format("MMM")}, ${moment().format(
        "YYYY"
      )} to ${moment().format("Do")} ${moment().format(
        "MMM"
      )}, ${moment().format("YYYY")}`;

      this.$store
        .dispatch(
          "report/getSummaryData",
          this.formatDates(
            moment(this.selectedDates.startDate).date(1),
            this.selectedDates.endDate
          )
        )
        .then(() => {
          this.fetchTopPayers();
          this.fetchAllPayments();
        })
        .catch((err) => {
          console.log(err);
          this.loadingPayments = false;
        });
    },
  },
  created() {
    this.initPayments();
  },
  mounted() {
    this.paymentInterval = setInterval(() => {
      this.initPayments();
    }, 10000);
  },
  unmounted() {
    clearInterval(this.paymentInterval);
  },
};
</script>

<style></style>
