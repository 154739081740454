<template>
  <section class="top-payers text-gray-600 body-font bg-gray-200 shadow-lg">
    <div class="px-5 py-2">
      <div class="-my-8 divide-y-2 divide-gray-100">
        <div
          v-for="payer in topPayers"
          :key="payer.payment_ref_no"
          class="py-8 flex flex-wrap md:flex-nowrap"
        >
          <div class="md:flex-grow">
            <h2
              class="sm:text-2xl text-lg font-medium text-gray-700 title-font mb-2"
            >
              {{ payer.customer_name }}
            </h2>
            <p class="leading-relaxed text-lg font-bold">
              ₦ {{ formatAmount(payer.Amount) }}
            </p>
            <button
              @click="viewPaymentDetails(payer)"
              class="text-blue-primary inline-flex items-center mt-4 focus:outline-none"
            >
              view details
              <svg
                class="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5l7 7-7 7"></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import numeral from "numeral";
export default {
  props: ["topPayers"],
  emits: ["viewDetails"],
  methods: {
    viewPaymentDetails(payer) {
      this.$emit("viewDetails", payer);
    },
    formatNumber(num) {
      return numeral(num).format("0,0");
    },
    formatAmount(amt) {
      return numeral(amt).format("0,0.00");
    },
  },
};
</script>

<style></style>
