<template>
  <div
    v-if="allPayers.result.length > 0"
    class="mx-0 sm:mx-0 overflow-x-auto mt-6 sm:mt-0 "
  >
    <div class="h-102 overflow-auto py-2 align-middle inline-block min-w-full ">
      <div class=" overflow-hidden  sm:rounded-lg bg-gray-200 ">
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-300 border-b border-gray-50 ">
            <tr>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-bold text-gray-600 uppercase tracking-wider"
              >
                Name
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-bold text-gray-600 uppercase tracking-wider"
              >
                Amount
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-bold text-gray-600 uppercase tracking-wider"
              >
                Payment Date
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-bold text-gray-600 uppercase tracking-wider"
              >
                Bank
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-bold text-gray-600 uppercase tracking-wider"
              >
                Branch
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-bold text-gray-600 uppercase tracking-wider"
              >
                Item
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-bold text-gray-600 uppercase tracking-wider"
              >
                Ref
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr
              v-for="payer in allPayers.result"
              :key="payer.company_id"
              class="bg-gray-200 border-b border-gray-50"
            >
              <td class="px-6 py-4 border-b border-gray-50 whitespace-nowrap">
                <div class="text-sm font-medium text-gray-900">
                  {{ payer.customer_name }}
                </div>
              </td>
              <td class="px-6 py-4 border-b border-gray-50 break-normal">
                <div class="text-sm text-gray-900 w-auto">
                  ₦{{ formatAmount(payer.Amount) }}
                </div>
              </td>
              <td class="px-6 py-4 border-b border-gray-50 break-normal">
                <div class="text-sm text-gray-900 w-48">
                  {{ payer.payment_date_time }}
                </div>
              </td>
              <td class="px-6 py-4 border-b border-gray-50 whitespace-nowrap">
                <div class="text-sm font-medium text-gray-900">
                  {{ payer.bank }}
                </div>
              </td>
              <td class="px-6 py-4 border-b border-gray-50 break-normal">
                <div class="text-sm text-gray-900 w-32">
                  {{ payer.bank_branch }}
                </div>
              </td>
              <td class="px-6 py-4 border-b border-gray-50 break-normal">
                <div class="text-sm text-gray-900 w-80">
                  {{ payer.revenue_item }}
                </div>
              </td>
              <td class="px-6 py-4 border-b border-gray-50 break-normal">
                <div class="text-sm text-gray-900 w-96">
                  {{ payer.payment_ref_no }}
                </div>
              </td>
            </tr>

            <!-- More items... -->
          </tbody>
        </table>
      </div>
    </div>
    <div
      class="bg-white py-3 flex items-center justify-between border-t border-gray-200"
    >
      <div class="flex-1 flex justify-between sm:hidden">
        <button
          @click="gotoPrevRecords"
          v-if="allPayers.previous"
          class="focus:outline-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
        >
          Previous
        </button>
        <button
          @click="gotoNextRecords"
          v-if="allPayers.next"
          class="focus:outline-none ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
        >
          Next
        </button>
      </div>
      <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <!-- <div>
            <p class="text-sm text-gray-700">
              Showing
              <span class="font-medium">{{ search.startNum + 1 }}</span>
              to
              <span class="font-medium">{{ search.tracker }}</span>
              of
              <span class="font-medium">{{ getCompaniesCount }}</span>
              results
            </p>
          </div> -->
        <div>
          <nav
            class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            <button
              @click="gotoPrevRecords"
              v-if="allPayers.previous"
              class="relative focus:outline-none inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span class="sr-only">Previous</span>
              <!-- Heroicon name: solid/chevron-left -->
              <svg
                class="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>

            <button
              @click="gotoNextRecords"
              v-if="allPayers.next"
              class="relative focus:outline-none inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span class="sr-only">Next</span>
              <!-- Heroicon name: solid/chevron-right -->
              <svg
                class="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import numeral from "numeral";
export default {
  props: ["selectedDates", "allPayers"],
  emits: ["nextRecords", "prevRecords", "loadData"],
  methods: {
    gotoNextRecords() {
      this.$emit("nextRecords");
      this.$emit("loadData");
    },
    gotoPrevRecords() {
      this.$emit("prevRecords");
      this.$emit("loadData");
    },
    formatNumber(num) {
      return numeral(num).format("0,0");
    },
    formatAmount(amt) {
      return numeral(amt).format("0,0.00");
    },
  },
  mounted() {},
};
</script>

<style></style>
